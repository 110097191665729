//  FD Logo in head of nav
.site-logo {
      width: 100%;
      height: 100%;
      background-image: url(/assets/images/FederatedDirectory_horizontal.svg);
      background-position: left center;
      background-repeat: no-repeat;
      background-size: contain;
    }

//  Hide "this site uses" in nav
.site-footer {
     display: none;
    }


    // Light border around images
img {
   border: 1px solid #CFD8DC
  }